export const proyectos = [
    {
        name: 'AvanProy',
        description: 'Avanproy es una aplicación web para llevar el control el avance de los proyectos de la empresa.',
        tech: ['React', 'JavaScript', 'HTML', 'CSS', 'NodeJs', 'Firebase'],
        link: 'https://demo-avanproy.enriquechacon.com',

    },
    
    {
        name: 'Azul historico',
        description: 'Página web de un restaurante, donde se permite crear usuarios y realizar reservaciones.',
        tech: ['JavaScript', 'HTML', 'CSS', 'SQL', 'Firebase'],
        link: 'http://demo-restaurante.enriquechacon.com/',

    },

    {
        name: 'Airbnb Clon',
        description: 'Copia de la interfaz de la página de Airbnb',
        tech: ['React', 'JavaScript', 'HTML', 'CSS', 'TailwindCss'],
        link: 'https://airbnb-clone.enriquechacon.com',

    },

    {
        name: 'Chat',
        description: 'Aplicación web sencilla de sala de mensajes en NodeJs y Socket.io',
        tech: ['JavaScript', 'HTML', 'CSS', 'NodeJs', 'Socket.io'],
        link: 'http://demo-chat.enriquechacon.com/',

    },

    {
        name: 'HeroesApp',
        description: 'Aplicación realizada en el curso de ReactJs. HeroesApp es una aplicación para consultar información sobre superhéroes.',
        tech: ['React', 'JavaScript', 'HTML', 'CSS', 'Bootstrap'],
        link: 'https://heroes-app.enriquechacon.com',

    },


]