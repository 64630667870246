import React from 'react';
import '../css/about.css';
import { Projects } from './Projects';

export const AboutMe = ({ refAbout }) => {
    return (
        <div className="section-container">
            <h1>Sobre Mí</h1>
            <p>
                Hola bienvenido a mi pagina personal En este sitio
                podrás encontrar información acerca de mis habilidades y
                algunos los proyectos en los que he trabajado.
            </p>
            <p>
                Actualmente soy estudiante de la ingeniería en sistemas
                computacionales. Me gusta todo acerca de las tecnologías
                de la información y estar constantemente aprendiendo.
            </p>

            <h1>Contacto</h1>
            <p>Enrique Chacón Mena</p>
            <p>Villahermosa, Tabasco, Mexico.</p>

            
            <h1>Educación</h1>
            <p>Universidad Juárez Autónoma de Tabasco</p>
            <p>Ingeniería en Sistemas Computacionales •2021</p>

            <h1>Proyectos</h1>
            <Projects />
        </div>
    );
};
