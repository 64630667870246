import { useRef } from 'react';
import { AboutMe as Content } from './components/AboutMe';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { ImageSlider } from './components/ImageSlider';
import { Navbar } from './components/Navbar';
import { Projects } from './components/Projects';
// import { Resume } from './components/Resume';
import './css/main.css'

function App() {
    const refAbout = useRef(null);
    const handleRefScroll = () => {
        refAbout.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    return (
        <div className="App">
            {/* <Navbar /> */}
            <Header handleRefScroll={handleRefScroll} />
            <div className='sections-container'>
                <Content refAbout={refAbout} />
            </div>
            {/* <Resume /> */}
            {/* <Footer /> */}
            {/* <Trabajos /> */}
            {/* <ImageSlider/> */}
        </div>
    );
}

export default App;
