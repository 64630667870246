import React, { useState, useEffect, useMemo } from 'react';

export const EfectoEscritura = React.memo(() => {
    const [index, setIndex] = useState(0);
    const [text, setText] = useState(0);
    const [reverse, setReverse] = useState(false);
    const [end, setEnd] = useState(false);

    const texto = useMemo(() => {
        const texto = [
            '',
            'Hola, bienvenido!    ',
            'Este es mi portafolio.    ',
            'Enrique Chacón Mena. 😎',
        ];

        return texto;
    }, []);

    // typeWriter
    useEffect(() => {
        if (end) {
            return;
        }

        const interval = setInterval(() => {
            if (index > texto[text].length) {
                setReverse(true);
            }
            if (index < 3) {
                setReverse(false);
            }
            if (index === 1) {
                if (text < texto.length - 1) {
                    setText(text + 1);
                }
            }
            if (
                text === texto.length - 1 &&
                index === texto[texto.length - 1].length - 1
            ) {
                setEnd(true);
            }

            setIndex(index + (reverse ? -1 : +1));
        }, 50);

        return () => clearInterval(interval);
    }, [index, end, reverse, text, texto]);

    return (
        <>
            <h1 className="titulo">
                {''}
                {texto[text].slice(0, index)}
            </h1>
        </>
    );
});
