import '../css/Header.css';
import { BackgroundStars } from './BackgroundStars';
import { EfectoEscritura } from './EfectoEscritura';
import { MediaLinks } from './MediaLinks';

export const Header = ({ handleRefScroll }) => {
    return (
        <div className="div-container">
            <div className="div-center">
                <BackgroundStars />
                <EfectoEscritura />
                <MediaLinks />
            </div>
            <svg
                onClick={handleRefScroll}
                xmlns="http://www.w3.org/2000/svg"
                className="arrowDown"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"
                />
            </svg>
        </div>
    );
};
