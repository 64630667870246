import React from 'react'

export const ProjectCard = ({name, description, tech, link}) => {
  return (
      <div className='project-card' onClick={e => { window.open(`${link}`)}}>
        <p className='name'>{name}</p>
        <p className='description'>{description}</p>
          <ul className='tech'>
              {
                  tech.map((element) => <li>{element}</li>)
              }
        </ul>
      </div>
  )
}
