import React from 'react'
import { proyectos } from '../assets/proyectos'
import { ProjectCard } from './ProjectCard'

export const Projects = () => {
  return (
    <div className='projects-container'>
      {
        proyectos.map((proyecto) => (
          <ProjectCard
            {...proyecto}
          />
        ))
      }
    </div>
  )
}
